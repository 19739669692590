import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
/**
 * Services
 */
import { ConnectService } from '../../../service/connect/connect.service';
import { PersistanceService } from '../../../service/persistance/persistance.service';
import { UserService } from '../../../service/user/user.service';
import { version } from '../../../../../package.json';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  name: Observable<string>;
  menuQuestions: string;
  menuDisconnect: string;
  version: string = version;

  constructor(
    private readonly connectService: ConnectService,
    private readonly persistanceService: PersistanceService,
    private readonly router: Router,
    private readonly userService: UserService
  ) {}

  ngOnInit(): void {
    this.menuQuestions = 'Foire Aux Questions';
    this.menuDisconnect = 'Se déconnecter';

    this.name = this.connectService.isDoneLoading$.pipe(
      map(() => {
        let userInfos;
        this.userService.getUserInfo().subscribe((data) => (userInfos = data));
        return userInfos.firstname + ' ' + userInfos.lastname;
      })
    );
  }

  /**
   * Redirect to the faq page.
   */
  goToFAQ(): void {
    this.persistanceService.set('faq_return_path', this.router.url);
    this.router.navigateByUrl('/faq');
  }

  /**
   * Log out user from connect service.
   */
  logout(): void {
    this.connectService.logOut();
  }
}
