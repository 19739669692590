import { AfterViewChecked, Component, HostListener, OnInit } from '@angular/core';
import { NavBarService } from './service/nav-bar/nav-bar.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, AfterViewChecked {
  constructor(private readonly navBarService: NavBarService) {}

  ngOnInit(): void {}

  ngAfterViewChecked(): void {
    this.updateCSS();
  }

  /**
   * Method triggered when user is resizing the window.
   */
  @HostListener('window:resize')
  onResize(): void {
    this.updateCSS();
  }

  private updateCSS(): void {
    let navBarPresent;
    this.navBarService.showNavBar$.subscribe((_) => (navBarPresent = _));
    const mainHeader = document.getElementById('main-header');
    const restaurantHeader = document.getElementById('header');
    const navbarComp = document.getElementById('nav-bar-component');
    const navbarLoaded = document.getElementById('nav-bar-loaded');
    const facilityHeader = document.getElementById('restaurant-facilities-header');
    const deliveryHeader = document.getElementById('restaurant-deliveries-header');
    if (navBarPresent && window.innerWidth >= 700 && navbarLoaded) {
      this.addNavBarClass(mainHeader);
      this.addNavBarClass(restaurantHeader);
      this.addNavBarClass(facilityHeader);
      this.addNavBarClass(deliveryHeader);
      navbarComp.classList.add('nav-bar-tablet-visible');
    } else {
      this.removeNavBarClass(mainHeader);
      this.removeNavBarClass(restaurantHeader);
      this.removeNavBarClass(facilityHeader);
      this.removeNavBarClass(deliveryHeader);
      navbarComp.classList.remove('nav-bar-tablet-visible');
    }
  }

  addNavBarClass(element: any): void {
    if (element && element !== undefined) {
      element.classList.add('nav-bar-space');
    }
  }
  removeNavBarClass(element: any): void {
    if (element && element !== undefined) {
      element.classList.remove('nav-bar-space');
    }
  }
}
